import { APP_CONST } from "../../config/const";
import {
  APP_DETAILS,
  BANK_DETAILS,
  BANNERS,
  SHOW_RIGHT_SIDEBAR,
  BETBY_REDUX,
} from "./types";

const initialState = {
  appDetails:
    localStorage.getItem("appDetails") !== null
      ? JSON.parse(localStorage.getItem("appDetails"))
      : null,
  bankDetails: null,
  betbyDetails: sessionStorage.getItem("betbyDetails")
    ? JSON.parse(sessionStorage.getItem("betbyDetails"))
    : null,
  banners:
    localStorage.getItem("banners") !== null
      ? JSON.parse(localStorage.getItem("banners"))
      : null,
  organisationStatus:
    localStorage.getItem("organisation_status") ||
    APP_CONST.ORGANISATION_STATUS.Live,
  showRightSideBar: false,
};

export default (state = initialState, actions) => {
  const { payload, type } = actions;
  switch (type) {
    case APP_DETAILS:
      localStorage.setItem("appDetails", JSON.stringify(payload));
      localStorage.setItem(
        "organisation_status",
        payload?.BRAND_STATUS || APP_CONST.ORGANISATION_STATUS.Live
      );
      return {
        ...state,
        appDetails: payload,
        organisationStatus: payload?.BRAND_STATUS,
      };
      return {
        ...state,
        appDetails: payload,
      };
    case BANK_DETAILS:
      return {
        ...state,
        bankDetails: payload,
      };
    case BANNERS:
      payload.sort((a, b) => a.order - b.order);
      localStorage.setItem("banners", JSON.stringify(payload));
      return {
        ...state,
        banners: Array.isArray(payload)
          ? payload?.filter((f) => f?.order <= 100)
          : [],
      };
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSideBar: payload,
      };
    case BETBY_REDUX:
      const finalPayload = { ...payload, lastUpdated: Date.now() };
      sessionStorage.setItem("betbyDetails", JSON.stringify(finalPayload));
      return {
        ...state,
        betbyDetails: finalPayload,
      };
    default:
      return state;
  }
};
