import React, { Suspense, useEffect } from "react";
import "./App.scss";
import Footer from "./containers/Footer";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./routing/ProtectedRoutes";
import PublicRoutes from "./routing/PublicRoutes";
import { useDispatch, useSelector } from "react-redux";
import { getAppDetails } from "./redux/app/actions";
import LoaderImg from "./assets/images/Load-gif.gif";
import { APP_CONST } from "./config/const";

const GamesRule = React.lazy(() =>
  import("./views/pages/privatePages/FooterPages/GamesRule")
);
const BeforeContactUs = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/BeforeContactUs")
);
const BeforePrivacyPolicy = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/BeforePrivacyPolicy")
);
const BeforeResponsibleGame = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/BeforeResponsibleGame")
);
const BeforeTerms = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/BeforeTerms")
);
const Bonus = React.lazy(() => import("./views/pages/privatePages/bonus"));
const BonusCard = React.lazy(() =>
  import("./views/pages/privatePages/bonus/bonusCard")
);
const SlotsGames = React.lazy(() => import("./views/pages/privatePages/Slots"));
const Spribe = React.lazy(() => import("./views/pages/privatePages/Spribe"));
const DisputeResolutions = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/DisputeResolutions")
);
const Payouts = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/Payouts")
);
const Registration = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/Registration")
);
const SelfExclusion = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/SelfExclusion")
);
const Wallet = React.lazy(() => import("./views/pages/privatePages/Wallet"));
const Referral = React.lazy(() =>
  import("./views/pages/privatePages/referral")
);
const HeaderAfterLogin = React.lazy(() =>
  import("./containers/HeaderAfterLogin")
);
const Promotion = React.lazy(() =>
  import("./views/pages/privatePages/Promotion")
);
const BeforePromotion = React.lazy(() =>
  import("./views/pages/publicPages/BeforePromotion")
);
const GatewaysList = React.lazy(() =>
  import("./views/pages/privatePages/deposit/gatewaysList")
);
const DepositGateway = React.lazy(() =>
  import("./views/pages/privatePages/deposit/depositGateway")
);
const BeforeExchange = React.lazy(() =>
  import("./views/pages/privatePages/exchange/BeforeExchange")
);
const QtechLobbyPage = React.lazy(() =>
  import("./views/pages/privatePages/qtech/QtechLobbyPage")
);

const ProviderPage = React.lazy(() =>
  import("./views/pages/privatePages/ProviderPage")
);
const CardGames = React.lazy(() =>
  import("./views/pages/privatePages/CardGames")
);
const Exchange = React.lazy(() => import("./views/pages/publicPages/Exchange"));
// static page start
const JetxPage = React.lazy(() =>
  import("./views/pages/publicPages/StaticPages/JetxPage")
);
const AvaitorPage = React.lazy(() =>
  import("./views/pages/publicPages/StaticPages/AvaitorPage")
);
const CricketBettingPage = React.lazy(() =>
  import("./views/pages/publicPages/StaticPages/CricketBettingPage")
);
const CricketIdPage = React.lazy(() =>
  import("./views/pages/publicPages/StaticPages/CricketIdPage")
);
const RoulettePage = React.lazy(() =>
  import("./views/pages/publicPages/StaticPages/RoulettePage")
);
const T20MatchesPage = React.lazy(() =>
  import("./views/pages/publicPages/StaticPages/T20MatchesPage")
);
const TeenpattiPage = React.lazy(() =>
  import("./views/pages/publicPages/StaticPages/TeenpattiPage")
);
const CasinoPage = React.lazy(() =>
  import("./views/pages/publicPages/StaticPages/CasinoPage")
);
// static page end
const ProviderPageBefore = React.lazy(() =>
  import("./views/pages/publicPages/ProviderPageBefore")
);
const Home = React.lazy(() => import("./views/pages/privatePages/home"));
const Games = React.lazy(() => import("./views/pages/privatePages/games"));
const DynamicCasinoPage = React.lazy(() =>
  import("./views/pages/privatePages/games/DynamicCasinoPage")
);
const Cashier = React.lazy(() => import("./views/pages/privatePages/cashier"));
const LandingPage = React.lazy(() =>
  import("./views/pages/publicPages/landingPage")
);
const Withdraw = React.lazy(() =>
  import("./views/pages/privatePages/withdraw")
);
const Register = React.lazy(() => import("./views/pages/publicPages/register"));
const Login = React.lazy(() => import("./views/pages/publicPages/login"));
const MobileNumberVerify = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumberVerify")
);
const MobileNumber = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumber")
);
const Profile = React.lazy(() => import("./views/pages/privatePages/profile"));

const ExchangeLobbyPage = React.lazy(() =>
  import("./views/pages/privatePages/exchange/ExchangeLobbyPage")
);

const MyBets = React.lazy(() => import("./views/pages/privatePages/myBets"));
const WithdrawList = React.lazy(() =>
  import("./views/pages/privatePages/withdraw/WithdrawList")
);
const ForgotPassword = React.lazy(() =>
  import("./views/pages/publicPages/forgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/publicPages/forgotPassword/resetPassword")
);
const Betby = React.lazy(() => import("./views/pages/privatePages/betby"));
const GamesCategory = React.lazy(() =>
  import("./views/pages/privatePages/Slots/gameCategory")
);
const DynamicGames = React.lazy(() =>
  import("./views/pages/privatePages/Slots/dynamicGames")
);
const Maintenance = React.lazy(() =>
  import("./views/pages/publicPages/Maintenance")
);

const Kingmaker = React.lazy(() =>
  import("./views/pages/privatePages/Kingmaker")
);

const App = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { appDetails, organisationStatus } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = appDetails?.SEO_TITLE || "";
    document.canonical = appDetails?.LOGO_URL;
    // check organisation status
    if (
      organisationStatus !== null &&
      organisationStatus !== APP_CONST.ORGANISATION_STATUS.Live &&
      window.location.pathname.indexOf("maintenance") < 0
    ) {
      window.location.href = "/maintenance";
    }
  }, [appDetails]);

  useEffect(() => {
    dispatch(getAppDetails());
    setVhUnit();
    window.addEventListener("resize", setVhUnit);
    return () => {
      window.removeEventListener("resize", setVhUnit);
    };
  }, []);

  const setVhUnit = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  return (
    <Router>
      <ToastContainer
        closeButton={false}
        position="top-right"
        autoClose={1000}
      />

      <Suspense
        fallback={
          <div className="loader-img">
            <img src={LoaderImg} alt="loader" />
          </div>
        }
      >
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoutes isAuth={isAuth}>
                <LandingPage />
              </PublicRoutes>
            }
          />
          <Route
            path="/maintenance"
            element={<Maintenance organisationStatus={organisationStatus} />}
          />
          <Route
            path="/dynamicGames/:providerName"
            element={<DynamicGames />}
          />

          <Route path="/game/:category" element={<GamesCategory />} />

          <Route
            path="/home"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Home />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/wallet"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Wallet />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/games-all"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Games />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/provider"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ProviderPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/beforeprovider"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ProviderPageBefore />
              </PublicRoutes>
            }
          />
          <Route
            path="/cardgames"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <CardGames />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug/:gameslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/cashier"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Cashier />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/withdrawList"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <WithdrawList />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/mybets"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <MyBets />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/deposit/:gateway"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DepositGateway />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/gatewaylist"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <GatewaysList />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/Slots/:slotName"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <SlotsGames />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/kingmaker"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Kingmaker />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/Spribe"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Spribe />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/withdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Withdraw />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Register />
                <Footer />
              </PublicRoutes>
            }
          />
          <Route
            path="/sign-in"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Login />
              </PublicRoutes>
            }
          />
          <Route
            path="/verify-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumberVerify />
              </PublicRoutes>
            }
          />
          <Route
            path="/mobile-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumber />
              </PublicRoutes>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Profile />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/exchange/:gameId"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <div className="header-bg">
                  <HeaderAfterLogin />
                </div>
                <ExchangeLobbyPage />
              </ProtectedRoutes>
            }
          />
          <Route
            // path="/sports/exchange"
            path="/sports-exchange"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeExchange />
              </PublicRoutes>
            }
          />
          <Route path="/betby" element={<Betby isAuth={isAuth} />} />
          <Route path="/promotion" element={<Promotion isAuth={isAuth} />} />
          <Route
            path="/promotions"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforePromotion />
              </PublicRoutes>
            }
          />
          <Route
            path="/beforeexchange"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Exchange />
              </PublicRoutes>
            }
          ></Route>
          <Route
            path="/jetx"
            element={
              <PublicRoutes isAuth={isAuth}>
                <JetxPage />
              </PublicRoutes>
            }
          ></Route>
          <Route
            path="/avaitor"
            element={
              <PublicRoutes isAuth={isAuth}>
                <AvaitorPage />
              </PublicRoutes>
            }
          ></Route>
          <Route
            path="/cricket-betting"
            element={
              <PublicRoutes isAuth={isAuth}>
                <CricketBettingPage />
              </PublicRoutes>
            }
          ></Route>
          <Route
            path="/cricket-id"
            element={
              <PublicRoutes isAuth={isAuth}>
                <CricketIdPage />
              </PublicRoutes>
            }
          ></Route>
          <Route
            path="/t20-matches"
            element={
              <PublicRoutes isAuth={isAuth}>
                <T20MatchesPage />
              </PublicRoutes>
            }
          ></Route>
          <Route
            path="/casino"
            element={
              <PublicRoutes isAuth={isAuth}>
                <CasinoPage />
              </PublicRoutes>
            }
          ></Route>
          <Route
            path="/teenpatti"
            element={
              <PublicRoutes isAuth={isAuth}>
                <TeenpattiPage />
              </PublicRoutes>
            }
          ></Route>
          <Route
            path="/roulette"
            element={
              <PublicRoutes isAuth={isAuth}>
                <RoulettePage />
              </PublicRoutes>
            }
          ></Route>
          <Route
            path="/gamesrule"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <GamesRule />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/disputeresolution"
            element={<DisputeResolutions isAuth={isAuth} />}
          />
          <Route path="/payout" element={<Payouts isAuth={isAuth} />} />
          <Route
            path="/bonus"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Bonus />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/referral"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Referral />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/bonus/:id"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <BonusCard />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/contactus"
            element={<BeforeContactUs isAuth={isAuth} />}
          />
          <Route
            // path="/sports/exchange"
            path="/qtechlobby"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <QtechLobbyPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/registration"
            element={<Registration isAuth={isAuth} />}
          />
          <Route
            path="/selfexclusion"
            element={<SelfExclusion isAuth={isAuth} />}
          />
          <Route
            path="/privacypolicy"
            element={<BeforePrivacyPolicy isAuth={isAuth} />}
          />
          <Route
            path="/responsiblegames"
            element={<BeforeResponsibleGame isAuth={isAuth} />}
          />
          <Route path="/terms" element={<BeforeTerms isAuth={isAuth} />} />
          <Route
            path="/forgot-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ForgotPassword />
              </PublicRoutes>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ResetPassword />
              </PublicRoutes>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
